<template>
  <b-form-row>
    <b-col>
      <h4 class="page-sub-header pl-3 pb-2">Edit sample data</h4>
      <div class="b-table-sticky-header custom-scroll h-auto" v-if="tableSampleData">
        <table class="table" ref="table">
          <thead>
            <tr>
              <th
                :width="hIndex == 1 || hIndex == 2 ? '25%' : null"
                v-for="(head, hIndex) in tableSampleData.head"
                :key="hIndex"
              >
                <input
                  type="text"
                  class="form-control"
                  v-model="tableSampleData.head[hIndex]"
                  @input="onChangeSampleData"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tr, trIndex) in tableSampleData.body" :key="trIndex">
              <td v-for="(td, tdIndex) in tr" :key="tdIndex">
                <input
                  type="text"
                  class="form-control"
                  v-model="tr[tdIndex]"
                  @input="onChangeSampleData"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-col>
  </b-form-row>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import ColumnResizer from 'column-resizer'
export default {
  name: 'CustomizeTableData',
  data () {
    return {
      columnResizer: null
    }
  },
  computed: {
    ...mapState({
      tableSampleData: (state) => state.widgets.tableSampleData
    })
  },
  beforeCreate () {
    this.$store.dispatch('widgets/defaultTableSampleData')
  },
  mounted () {
    setTimeout(() => {
      this.columnResizer = new ColumnResizer(this.$refs.table, {
        liveDrag: true,
        flush: true,
        draggingClass: 'rangeDrag',
        gripInnerHtml: "<div class='rangeGrip'></div>",
        minWidth: 8
      })
    }, 1000)
  },
  destroyed () {
    if (this.columnResizer) {
      this.columnResizer.destroy()
    }
  },
  methods: {
    onChangeSampleData () {
      this.$store.commit(
        'widgets/setTableSampleData',
        cloneDeep(this.tableSampleData)
      )
    }
  }
}
</script>
<style lang="scss" scoped>
input {
  height: 20px !important;
  border-radius: 0 !important;
  border: 0 !important;
  font-size: 13px;
  text-overflow: ellipsis;
  padding: 0;
}
.table {
  background: #fff;
}
.table thead th,
.table td {
  padding: 0.2rem 0.1rem;
  border: 1px solid #cccccc !important;
  border-bottom: 1px solid #cccccc !important;
  padding-left: 0.5rem !important;
}
.form-control:focus {
  box-shadow: none;
}
</style>
